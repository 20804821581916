import { NextPage, GetStaticPropsContext } from "next";
import { getGlobals } from "@models/globals.model";
import { getModulePage, getModulePagesPaths, ModulePage } from "@models/module-page.model";
import { Sanity } from "@models/sanity.model";
import Layout from "@components/layout";
import ComponentResolver from "@components/component-resolver";
import { getSectioningContext } from "@components/component-resolver/sectionContext";
import StickySubnav from "@components/subnav/sticky-subnav";

interface CatchAllPageProps extends Sanity.PageProps {
  page: ModulePage;
}

const Page: NextPage<CatchAllPageProps> = ({ globals, page }) => {
  const {
    hide_header_banner,
    marketing_modules,
    seo,
    transparent_header,
    show_sticky_subnav,
    sticky_subnav_heading,
    sticky_subnav_links,
  } = page ?? {};

  return (
    <Layout
      globals={globals}
      pageID={page?.slug?.current}
      hideBanner={hide_header_banner}
      transparentHeader={transparent_header}
      seo={seo}>
      {show_sticky_subnav && (
        <StickySubnav heading={sticky_subnav_heading} subnav_links={sticky_subnav_links} />
      )}

      {marketing_modules?.length > 0 &&
        marketing_modules.map((schema: Sanity.Document, i: number) => {
          const sectioningContext = getSectioningContext({ modules: marketing_modules, index: i });

          return (
            <ComponentResolver
              key={`${schema._type}_${i}`}
              schema={schema}
              sectioningContext={sectioningContext}
            />
          );
        })}
    </Layout>
  );
};

export default Page;

export async function getStaticProps(context: GetStaticPropsContext) {
  const [globals, page] = await Promise.all([
    await getGlobals(),
    await getModulePage(String(context.params?.slug)),
  ]);

  return {
    props: {
      globals,
      page,
    },
  };
}

export async function getStaticPaths() {
  const paths = await getModulePagesPaths();

  return { paths, fallback: false };
}
