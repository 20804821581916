import styled, { css } from "styled-components";
import { Color, Device, Z } from "@styles/constants";
import { rem } from "@styles/helpers";
import { LargeBodyMediumCSS, SmallBodyMediumCSS } from "@styles/typography";

export const StickySubnav = styled.nav<{ show: boolean }>`
  position: sticky;
  width: 100%;
  background: ${Color.white};
  z-index: ${Z.subnav};
  top: var(--banner-height);
  opacity: 0;
  visibility: none;
  max-height: 0;
  pointer-events: none;

  &:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: ${Color.goldAsh};
    position: absolute;
    left: 0;
    bottom: 0;
  }

  ${({ show }) =>
    show &&
    css`
      max-height: 1000px;
      opacity: 1;
      transition: opacity 0.5s ease-in, background 0.5s ease-in;
      visibility: visible;
      transform: none;
      pointer-events: all;

      @media ${Device.mediumDown} {
        height: auto;
      }
    `}

  .grid-container {
    height: 100%;
  }
`;

export const GridWrapper = styled.div<{ comparePlans: boolean }>`
  --sticky-subnav-height: ${rem(12.5)};

  display: flex;
  align-items: center;
  height: 100%;
  padding-top: var(--sticky-subnav-height);
  padding-bottom: var(--sticky-subnav-height);

  ${({ comparePlans }) =>
    comparePlans &&
    css`
      max-width: var(--compare-plans-container);
      margin: 0 auto;
      padding-top: ${rem(18)};
      padding-bottom: ${rem(20)};

      @media ${Device.largeUp} {
        display: grid;
        grid-template-columns: 1fr minmax(${rem(500)}, 1fr);
        max-width: unset;
        margin: 0;
        padding-top: var(--sticky-subnav-height);
        padding-bottom: var(--sticky-subnav-height);
      }

      h2 {
        margin-bottom: ${rem(7)};

        @media ${Device.largeUp} {
          margin-bottom: 0;
        }
      }

      ul {
        > li {
          text-align: center;
          justify-content: space-between;
        }
      }
    `}
`;

export const List = styled.ul`
  margin: 0;
  list-style: none;
  display: flex;
  width: 100%;

  @media ${Device.largeUp} {
    width: auto;
    gap: ${rem(20)};
  }
`;

export const ListItem = styled.li`
  flex-grow: 1;
  text-align: end;

  @media ${Device.largeUp} {
    display: inline-flex;
    align-items: center;
  }
`;

export const Heading = styled.div`
  display: none;

  @media ${Device.largeUp} {
    display: block;
    ${LargeBodyMediumCSS};
    flex-grow: 1;
  }
`;

export const LinkHeading = styled.h2`
  ${SmallBodyMediumCSS}
`;
