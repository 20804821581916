import useWindowSize from "@hooks/useWindowSize";
import { Sanity } from "@models/sanity.model";
import { useScroll } from "framer-motion";
import { useEffect, useState } from "react";
import * as Styled from "./StickySubnav.styled";
import ButtonHeader from "@components/ui/button-header";
import { useRouter } from "next/router";
import { comparePlansSlug } from "@utils/routes";

export interface StickySubnavProps {
  heading?: string;
  subnav_links: Sanity.LinkList;
}

const StickySubnav = ({ heading, subnav_links }: StickySubnavProps) => {
  const [showSubnav, setShowSubnav] = useState<boolean>(false);
  const { scrollY } = useScroll();
  const { height } = useWindowSize();
  const router = useRouter();

  useEffect(() => {
    const unsubscribeScrollY = scrollY.onChange((y) => {
      setShowSubnav(y > height * 0.1);
    });

    return () => {
      unsubscribeScrollY();
    };
  }, [scrollY, height]);

  return (
    <Styled.StickySubnav show={showSubnav}>
      <div className="grid-container">
        <Styled.GridWrapper comparePlans={router.asPath === comparePlansSlug}>
          {heading && <Styled.Heading>{heading}</Styled.Heading>}

          <Styled.List>
            {subnav_links.links.map(({ _key, heading, ctas }) => (
              <Styled.ListItem key={_key}>
                {heading && <Styled.LinkHeading>{heading}</Styled.LinkHeading>}
                {ctas?.length > 0 &&
                  ctas.map((cta, ctaIndex) => (
                    <ButtonHeader key={ctaIndex} text={cta.text} url={cta.url} />
                  ))}
              </Styled.ListItem>
            ))}
          </Styled.List>
        </Styled.GridWrapper>
      </div>
    </Styled.StickySubnav>
  );
};

export default StickySubnav;
