import styled from "styled-components";
import Link from "next/link";
import { Color, Device, EaseHeader } from "@styles/constants";
import { XSmallBodyMediumCSS } from "@styles/typography";

export interface ButtonHeaderProps {
  text: string;
  url: string;
  transparent?: boolean;
  tabIndex?: number;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  onClick?: () => void;
}

const StyledButtonHeader = styled.div<{ transparent?: boolean }>`
  a {
    ${XSmallBodyMediumCSS}
    height: 35px;
    background-color: ${Color.blackBrand};
    padding: 0 20px;
    color: ${Color.white};
    border-radius: 17.5px;
    cursor: pointer;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: color ${EaseHeader.duration.transition} ${EaseHeader.curve},
      background-color ${EaseHeader.duration.transition} ${EaseHeader.curve};

    @media ${Device.largeUp} {
      ${({ transparent }) =>
        transparent &&
        `
        background-color: ${Color.white};
        color: ${Color.blackBrand};
      `};
    }

    &:focus,
    &:focus-visible,
    &:hover {
      background-color: ${Color.grayBrand};
      color: ${Color.white};
      transition-duration: 0.35s;
    }
  }
`;

const ButtonHeader = ({
  text,
  url,
  transparent,
  tabIndex,
  onFocus,
  onMouseEnter,
  onClick,
}: ButtonHeaderProps) => {
  return (
    <StyledButtonHeader transparent={transparent}>
      <Link
        href={url}
        tabIndex={tabIndex}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onClick={onClick}>
        {text}
      </Link>
    </StyledButtonHeader>
  );
};

export default ButtonHeader;
